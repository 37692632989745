// eslint-disable-next-line import/prefer-default-export
export const reserveHero = {
  title:
    "Power your front-of-house with a restaurant reservation system and waitlist app",
  subtext:
    "SpotOn Reserve is a complete reservation solution that allows you to coordinate your online reservations, digital waitlist, and special guest experiences in one place.",
  heroImg: "reserve-hero.png",
  imageClassName: "fixed-width fixed-height",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/reserve/demo",
  },
  fatTitle: "Reserve",
};

export const reserveTrusted = [
  {
    title: "101m+",
    subtext: "Reservations served using SpotOn Reserve",
  },
  {
    title: "$800+",
    subtext:
      "Monthly savings over reservation platforms that charge per-cover fees",
  },
  {
    title: "2,000+",
    subtext: "New businesses joining the SpotOn family every month",
  },
  {
    title: "300m+",
    subtext: "Successful marketing campaigns deployed",
  },
];

export const reserveWhiteGlove = {
  mainTitle: "The help you need, every step of the way",
  title: "Technology with a human touch",
  content:
    "You've got a restaurant to run. That's why our team of experts does all the hard work of setting up your seating map, establishing your processes, and training your staff. And after you're up and running, you can count on 24/7/365 customer support from a partner who cares about your success.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/reserve/demo",
  },
};

export const reserveLargeFeatures = {
  sectionTitle: "More guests. Less stress",
  featureBlocks: [
    {
      blockTitle: "A reservation system that works for you",
      blockImg: "reserve-a.png",
      id: "reservations",
      blockSubTitle:
        "You need online reservations without third-party per-cover fees. SpotOn Reserve gives you a customizable restaurant reservation system with transparent pricing that can save you more than $800 a month. Plus you get complete control of your reservations.",
      blockList: [
        `Reduce no-shows. <span class="font-light">Lets guests confirm or cancel reservations with 2-way texting.</span>`,
        `Make the most of tables. <span class="font-light">See the best place to seat every party with automatic suggestions.</span>`,
        `Turn tables faster. <span class="font-light">Know and adjust your turn times to seat as many guests as possible.</span>`,
        `Collect marketing data. <span class="font-light">Automatically capture guest info and send them promotions through SpotOn Marketing.</span>`,
        `Integrate with Google. <span class="font-light">Make it easy for guests to book a table right on Google.</span>`,
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reserve/demo",
      },
    },
    {
      blockTitle: "Manage your wait with an intuitive waitlist app",
      blockImg: "reserve-b.png",
      id: "waitlist",
      blockSubTitle:
        "Ditch the pen and paper or isolated waitlist apps. SpotOn Reserve gives your hosts everything they need to seat guests faster while maintaining the pace of your restaurant.",
      blockList: [
        `Easy check-in. <span class="font-light">Allow guests to add themselves to the waitlist from your website or Google profile page.</span>`,
        `Automatic wait times. <span class="font-light">Send guests wait estimates automatically based on your turn times and table statuses.</span>`,
        `Real-time guest view. <span class="font-light">Let guests see their place in line with a real-time waitlist they can see from their phones.</span>`,
        `Two-way texting. <span class="font-light">Notify guests when their table is ready and let them respond through two-way text messages.</span>`,
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reserve/demo",
      },
    },
    {
      blockTitle: "Let your guests book special experiences",
      blockImg: "reserve-f.png",
      id: "experiences",
      blockSubTitle:
        "Introducing SpotOn Reserve's newest feature—Experiences. Easily accept and manage time-slot bookings for dining events or entertainment activities alongside your online reservations and guest waitlist.",
      blockList: [
        `Offer dinner experiences. <span class="font-light">From wine tastings to private parties, your full-service restaurant can effortlessly book special dining events.</span>`,
        `Coordinate entertainment activities. <span class="font-light">Whether your bar or restaurant has axe throwing, laser tag, or bowling, Experiences makes managing them simple.</span>`,
        `Accept deposits.  <span class="font-light">Minimize no-shows and last-minute cancellations by requiring deposits that you can customize as needed.</span>`,
        `Make booking easy. <span class="font-light">Allow guests to book their activity or experience in person, on your website, or through Google.</span>`,
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reserve/demo",
      },
    },
    {
      blockTitle: "Build better guest relationships",
      blockImg: "reserve-d.png",
      blockSubTitle:
        "SpotOn helps you collect guest data from multiple touchpoints so you can easily send marketing campaigns to improve guest loyalty and drive repeat visits.",
      blockList: [
        `Collect data. <span class="font-light">Make future check-ins easier by capturing helpful guest information like names, emails, phone numbers, preferences, and food allergies.</span>`,
        `Promote specials. <span class="font-light">Send email marketing promotions to guests with SpotOn Marketing and stay at the top of their minds throughout the year.</span>`,
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reserve/demo",
      },
    },
    {
      blockTitle: "Connect table management to your POS",
      blockImg: "reserve-e.png",
      blockSubTitle:
        "SpotOn Reserve can work on its own or integrate seamlessly with SpotOn Restaurant POS to give your front-of-house even further functionality.",
      blockList: [
        `Give hosts table information. <span class="font-light">Enable hosts to see where tables are in their meal with flags that show statuses like drinks, appetizer, main course, dessert, or check paid.</span>`,
        `Keep servers updated. <span class="font-light">Notify servers through the POS of special guest situations like VIP arrivals, birthdays, drink preferences, or food allergies.</span>`,
        `Get table-management analytics. <span class="font-light">See valuable metrics such as Daily Table Turns and Wait Time Efficiency to better improve seating times.</span>`,
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reserve/demo",
      },
    },
  ],
};

export const reserveBusinessTypesCar = {
  title: "Built for restaurants",
  subtext:
    "SpotOn Reserve is designed to help restaurants and other hospitality businesses. Streamline your front-of-house and create a top-notch guest experience.",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
  ],
};

export const reserveVideo = {
  title: "See SpotOn Reserve in action",
  videoBtn: "Watch video",
  posterImg: "reserve-poster.png",
  videoUrlId: "8zRkQuHq3dE",
};

export const reserveSolutionsSection = {
  title: "Tools that work together",
  subText: `Simplify operations by combining SpotOn Reserve with other restaurant solutions that work hand-in-hand. Our team of restaurant experts will set up everything for you, or we’ll work with your in-house IT team to leverage our developer API.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const reserveTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const reserveIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and reserve processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept reserves and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const reserveCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/reserve/demo",
  },
};

export const reserveFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const reserveSolutionsSlides = [
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Restaurants",
    link: "/restaurant-pos",
    img: "Restaurant.png",
  },
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Delivery",
    link: "/products/delivery",
    img: "Delivery.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
];
