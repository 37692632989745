import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  reserveHero,
  reserveTrusted,
  reserveWhiteGlove,
  reserveLargeFeatures,
  reserveBusinessTypesCar,
  reserveVideo,
  reserveSolutionsSection,
  reserveTestimonials,
  reserveCTA,
  reserveSolutionsSlides,
  // reserveFaqs,
} from "../../../data/product-pages/reserve-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import { cacioSlides, heroSuccess } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/ReserveLF")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "reserve-white-glove.png";

const reserve = () => {
  return (
    <Layout className="products-reserve-page">
      <SEO
        title="Reservation Platform for Restaurants| SpotOn"
        description="SpotOn's reservation platform includes integrated table management & server rotation tools. Streamline your front-of-house & turn tables faster. Demo now"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/reserve/"
      />
      <Hero sectionData={reserveHero} circleBg={false} />
      <TrustedNumbers numbersArray={reserveTrusted} />
      <WhiteGlove
        sectionData={reserveWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={reserveLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={reserveBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={cacioSlides} />
      <VideoSection sectionData={reserveVideo} />
      <PartnerLogos />
      <ProductLines
        sectionData={reserveSolutionsSection}
        productSlides={reserveSolutionsSlides}
      />
      <TestmonialReviews sectionData={reserveTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={reserveCTA} />
      {/* <Faqs sectionData={reserveFaqs} /> */}

      {/* <style jsx global>{`
        @media screen (min-width: 1000px) {
            #___gatsby .products-reserve-page .ant-layout-content .hero .hero__fat-title {
        font-size: calc((110vw - 5rem) / 5);
        }
        @media screen (min-width: 1200px) {
          #___gatsby .products-reserve-page .ant-layout-content .hero .hero__fat-title {
             font-size: calc((122vw - 5rem) / 7);
          }
        }
        @media screen (min-width: 1500px) {
          #___gatsby .products-reserve-page .ant-layout-content .hero .hero__fat-title {
             font-size: calc((75vw - 5rem) / 7);
          }
        }
      `}
      </style> */}
    </Layout>
  );
};

export default reserve;
